
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    textAlignment: {
      type: String,
      default: 'right',
    },
    textStatus: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'text-secondary',
    },
    negativeIndicator: {
      type: String,
      default: 'fas fa-check-circle',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    color() {
      if (this.modelValue) return 'text-success';

      return this.textColor ? this.textColor : 'text-danger';
    },
  },
});
