import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd2d0554"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pe-2"
}
const _hoisted_2 = {
  key: 3,
  class: "ps-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.textAlignment === 'left')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.textStatus ? _ctx.textStatus : ''), 1))
      : _createCommentVNode("", true),
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(`fas fa-check-circle ${_ctx.textColor}`)
        }, null, 2))
      : (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(`${_ctx.negativeIndicator} ${_ctx.textColor}`)
        }, null, 2)),
    (_ctx.textAlignment === 'right')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.textStatus ? _ctx.textStatus : ''), 1))
      : _createCommentVNode("", true)
  ], 64))
}