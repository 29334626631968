
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters } from '@/store/enums/AgencyEnums';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import timeUtil from '@/utils/time';

//import AgencyLogo from '@/views/new-design/pages/Agencies/AgencyLogo.vue';
import Chip from '@/components/Chip.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  name: 'agency-table',
  components: {
    Card,
    DataTable,
    Chip,
    StatusIndicator,
  },
  data: () => {
    return {
      checkedCustomers: [],
      table: {
        total: 0,
        loading: false,
        items: [],
        search: '',
      },
      debounceGetAgencies: Function(),
    };
  },
  computed: {
    ...mapGetters({
      agencies: Getters.GET_AGENCIES,
    }),
    agencyText() {
      return this.table.total > 1 ? 'agencies' : 'agency';
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        { name: 'Agency Name', key: 'business_name', sortable: true },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Sign Up Date',
          key: 'signup_date',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Users',
          key: 'user_count',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Submitted References',
          key: 'review_report_count',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Requested References',
          key: 'reference_request_count',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Referred Tenants',
          key: 'referred_tenants_count',
          sortable: true,
          hidden: this.isMobile,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAgencies: Actions.FETCH_AGENCIES,
    }),
    formatAgencyList() {
      return this.agencies.agencies.map((item) => {
        item.status = item?.abn_lookup?.AbnStatus != '' ? 'Active' : 'Inactive';
        return item;
      });
    },
    async getAgencies() {
      this.table.loading = true;
      await this.fetchAgencies()
        .then(() => {
          this.table.items = this.formatAgencyList();
          this.table.total = this.agencies.pagination.total;
        })
        .catch(() => {
          console.log('ERROR');
        })
        .finally(() => (this.table.loading = false));
    },
    getStatus(agency: any) {
      if (agency?.abn_status === '' || agency?.abn_status === null) {
        return {
          type: 'warning',
          text: 'Pending',
          value: false,
          textStatus: 'ABN',
        };
      }

      if (agency?.status === 'approved') {
        return { type: 'success', text: 'Active', value: true, textStatus: '' };
      }

      if (agency?.status === 'suspended') {
        return {
          type: 'warning',
          text: 'Suspended',
          value: false,
          textStatus: '',
        };
      }

      if (agency?.status === 'deleted') {
        return {
          type: 'danger',
          text: 'Deleted',
          value: false,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Inactive', value: false };
    },
    viewAgent(agent: any) {
      this.$router.push({
        name: 'view-agency',
        params: { agent_id: agent.id },
      });
    },
    onSearch(search) {
      this.table.search = search;
    },
  },
  created() {
    this.debounceGetAgencies = _.debounce(() => {
      this.getAgencies();
    }, 1000);
  },
  async mounted() {
    await this.getAgencies();
  },
});
